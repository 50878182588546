function BookingProcessorModule(bookingModule) {
    var self = this;

    self.bookingModule = bookingModule ? bookingModule : new BookingModule();

    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }
            ko.utils.arrayForEach(data, function (item) {
                target.push(self.addInformation(new BookingModel(item)));
            });
            return target;
        }

        return self.addInformation(new BookingModel(data));
    };

    self.addInformation = function (item) {
        item.loading = ko.observable(false);

        item.setAccepted = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Se vuoi, puoi inviare un messaggio al cliente',
                input: 'textarea',
                inputPlaceholder: 'Scrivi il messaggio',
                inputValidator: function (value) {
                    if (!value || value.length < 5)
                        return 'Devi scrivere una motivazione di almeno 5 caratteri!'
                },
                inputAttributes: {
                    minlength: 5
                },
                showCancelButton: true,
                cancelButtonColor: '#cccccc',
                confirmButtonText: 'Ok, Invia',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-light-gray',
                },
                buttonsStyling: false
            }).then(function (result) {
                item.loading(true);
                self.bookingModule.accepted(item.id(), result.value)
                    .then(function (response) {
                        if (response.success) {
                            item.state('accepted');
                            item.status(response.data.booking.status);
                        }
                        self.bookingModule.notifyResponse(response);
                        item.loading(false);
                    })
                    .catch(function () {
                        item.loading(false);
                    })
            })
        };

        item.setDenied = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Sei sicuro di volerla Rifiutare?',
                input: 'textarea',
                inputPlaceholder: 'Scrivi la motivazione',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una motivazione di almeno 10 caratteri!'
                },
                inputAttributes: {
                    minlength: 5
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, rifiuta',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.denied(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setCanceled = function () {
            if (item.loading()) {
                return;
            }
            if (!item.isVoidable()) {
                var html = 'Come da condizioni d\'uso, non puoi più annullare online la Richiesta, ';
                if (item.isAfterDate()) {
                    html += 'essendo stato superato l\'orario stabilito per l\'erogazione del servizio ';
                } else if (item.service_type()==='sentHome') {
                    html += 'è stata già accettatta ed essendo in esecuzione non puoi più annullarla online. '
                } else {
                    html += 'mancando meno di 30 minuti all\'orario stabilito per l\'erogazione del servizio. ';
                }
                html += 'Telefona subito all\'Attività ' + item.catering.name + ' al suo numero ' +
                  '<a href="tel:' + item.catering.phoneHome + '">' + item.catering.phoneHome + '</a> e comunicagli' +
                  ' a voce le tue esigenze, magari concordando una nuova soluzione.<br>' +
                  'Per eventuale assistenza, potrai scriverci ad assistenza@trovacigusto.com.';
                Swal.fire({
                    title: 'Attenzione: non puoi più annullare online la Richiesta',
                    html: html,
                    icon: 'warning',
                    width: 800,
                    confirmButtonText: 'Ok, ho capito',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                    },
                    buttonsStyling: false
                });
                return;
            }

            Swal.fire({
                title: 'Sei sicuro di volerla Cancellare?',
                input: 'textarea',
                inputPlaceholder: 'Scrivi la motivazione',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una motivazione di almeno 10 caratteri!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, cancella',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.canceled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('canceled');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Proponi la modifica al Cliente',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, invia',
                cancelButtonText: 'No, annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('proposed')
                                item.proposal().state('requested')
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalAnnulled = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Vuoi procedere con l\'annullamento?',
                html: 'Attenzione: non potrai inviare una nuova Controproposta.<br> ' +
                    'Annullando la Controproposta che hai già inviato stai anche definitivamente rifiutando ' +
                    'e perdendo la iniziale Richiesta del Cliente.',
                input: 'textarea',
                inputPlaceholder: 'Scrivi la motivazione',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una motivazione di almeno 10 caratteri!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, procedo',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposalAnnulled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied')
                                item.proposal().state('annulled')
                                item.proposal().state_note(result.value)
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalDenied = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Sei sicuro di voler rifiutare la controproposta?',
                input: 'textarea',
                inputPlaceholder: 'Scrivi la motivazione',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una motivazione di almeno 10 caratteri!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, rifiuta',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposalDenied(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied')
                                item.proposal().state('denied')
                                item.proposal().state_note(result.value)
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalAccepted = function () {
            if (item.loading()) {
                return;
            }

            function setAccepted() {
                item.loading(true);
                self.bookingModule.proposalAccepted(item.id())
                    .then(function (response) {
                        if (response.success) {
                            item.state('accepted')
                            item.proposal().state('accepted')

                        }
                        self.bookingModule.notifyResponse(response);
                        item.loading(false);
                    })
                    .catch(function () {
                        item.loading(false);
                    })
            }

            if (item.price() !== item.proposal().total()) {
                Swal.fire({
                    title: 'Accetti la Controproposta?',
                    html: 'L\'Attività nella sua Controproposta ha variato il totale da pagare.<br><br>\n' +
                      '                Accettando la Controproposta autorizzi il nuovo importo:',
                    showCancelButton: true,
                    cancelButtonColor: '#CC1100',
                    confirmButtonText: 'Sì, accetto',
                    cancelButtonText: 'No',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                        cancelButton: 'btn btn-danger',
                    },
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        setAccepted();
                    } else {
                        item.loading(false);
                    }
                })
            } else {
                setAccepted();
            }

        };

        item.setClaimed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'La Richiesta è stata completata?',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, completa',
                cancelButtonText: 'Annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.claimed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('claimed')
                            }
                            self.bookingModule.notifyResponse(response)
                            item.loading(false)
                        }).catch(function () {
                        item.loading(false)
                    })
                } else {
                    item.loading(false)
                }
            })
        };

        item.setDeclaredSeats = function () {
            if (item.loading()) {
                return
            }
            item.loading(true)
            Swal.fire({
                title: 'Se ci sono state variazioni, indica il numero effettivo di persone servite',
                input: 'text',
                inputValue: item.declared_seats() ? item.declared_seats() : item.seats(),
                inputPlaceholder: 'Numero di persone:',
                inputValidator: function (value) {
                    if (!value || value <= 0)
                        return 'Il numero di persone minimo è 1!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, modifica',
                cancelButtonText: 'Annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true)
                    self.bookingModule.declareSeats(item.id(), result.value)
                      .then(function (response) {
                          if (response.success) {
                              item.declared_seats(result.value)
                          }
                          self.bookingModule.notifyResponse(response)
                          item.loading(false)
                      }).catch(function () {
                        item.loading(false)
                    })
                } else {
                    item.loading(false)
                }

            })
        }

        //@TODO sostituire con tabella booking processing con gli stati di lavorazione
        item.setSent = function () {
            if (item.loading()) {
                return
            }
            Swal.fire({
                title: 'L\'Ordine è partito dalla tua sede?',
                html: 'Se confermi che quanto richiesto dal Cliente é partito, lo informiamo di ciò con notifica e via email.',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, confermo',
                cancelButtonText: 'Annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.claimed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('claimed');
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setPayed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Confermi che il cliente ha pagato il servizio?',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, confermo',
                cancelButtonText: 'Annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.confirmPayment(item.id())
                      .then(function (response) {
                          if (response.data.success) {
                              item.transaction().state('payed');
                          }
                          item.loading(false);
                      }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setMissed = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            Swal.fire({
                title: 'Confermi di voler dichiarare il "No Show" del Cliente, cioè la sua "non presenza" nel momento/luogo concordato per il servizio richiesto?',
                input: 'textarea',
                inputPlaceholder: 'Descrivici cosa è accaduto, chiederemo dettagli anche al Cliente',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una testo di almeno 10 caratteri!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, confermo',
                cancelButtonText: 'Annulla',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    self.bookingModule.missed(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('missed');
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }

            })
        };

        item.setAnnulled = function () {
            if (item.loading()) {
                return;
            }

            if (!item.isVoidable()) {
                var html = 'Come da condizioni d\'uso, non puoi più annullare online la Richiesta, '
                if (item.isAfterDate()) {
                    html += 'essendo stato superato l\'orario stabilito per l\'erogazione del servizio '
                } else if (item.service_type() === 'sentHome') {
                    html += 'è stata già accettatta ed essendo in esecuzione non puoi più annullarla online. '
                } else {
                    html += 'mancando meno di 30 minuti all\'orario stabilito per l\'erogazione del servizio. ';
                }
                html += 'Telefona subito al Cliente al suo numero ' +
                  '<a href="tel:' + item.phone() + '">' + item.phone() + '</a> e comunicagli' +
                  ' a voce l\'annullamento, magari concordando una nuova soluzione.<br>' +
                  'Per eventuale assistenza, potrai scriverci ad assistenza@trovacigusto.com.'
                Swal.fire({
                    title: 'Attenzione: non puoi più annullare online la Richiesta',
                    html: html,
                    width: 800,
                    icon: 'warning',
                    confirmButtonText: 'Ok, ho capito',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                    },
                    buttonsStyling: false
                });
                return;
            }

            Swal.fire({
                title: 'Sei sicuro di voler Annullare?',
                input: 'textarea',
                inputPlaceholder: 'Scrivi la motivazione',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'Devi scrivere una motivazione di almeno 10 caratteri!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Sì, annulla',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.annulled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('annulled');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.toggleArchivedCatering = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            self.bookingModule.archived(item.id(), 'catering', !item.catering_archived())
                .then(function (response) {
                    if (response.success) {
                        item.catering_archived(response.data.catering_archived);
                    }
                    self.bookingModule.notifyResponse(response);
                    item.loading(false);
                })
                .catch(function () {
                    item.loading(false);
                })
        };

        item.toggleArchivedUser = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            self.bookingModule.archived(item.id(), 'user', !item.user_archived())
                .then(function (response) {
                    if (response.success) {
                        item.user_archived(response.data.user_archived);
                    }
                    self.bookingModule.notifyResponse(response);
                    item.loading(false);
                }).catch(function () {
                item.loading(false);
            })
        };

        item.isAfterDate = function () {
            if (!item.service_datetime())
                return false;
            var now = moment();
            var eventDate = moment(item.service_datetime());
            return now.isAfter(eventDate);
        }

        item.isVoidable = function () {
            if (item.state() !== 'accepted') {
                return true;
            }
            if (item.transaction().state === 'payed') {
                return false;
            }
            if (item.service_datetime()) {
                var now = moment().add(30, 'minutes');
                var eventDate = moment(item.service_datetime());
                return now.isSameOrBefore(eventDate);
            }
            if (item.service_type()==='sentHome' && item.state() === 'accepted') {
                return false;
            }
            return true
        }

        item.isTerminated = ko.computed(function () {
            if (item.state() === 'expired')
                return true
            if (item.state() !== 'requested')
                return false
            var isAfter = item.isAfterDate()
            if (isAfter)
                item.state('expired')
            return !!isAfter
        })

        item.getSeats = ko.computed(function () {
            if (item.declared_seats() && item.declared_seats() !== item.seats())
                return item.declared_seats() + ' <span title="Richiesta iniziale: ' + item.seats() + '">(<del>' + item.seats() + '</del>)</span>'
            return item.seats()
        })

        item.getSeatsProposal = ko.computed(function () {
            if (!item.proposal())
                return
            if (item.declared_seats() && item.declared_seats() !== item.proposal().seats())
                return item.declared_seats() + ' <span title="Cotroproposta: ' + item.proposal().seats() + '">(<del>' + item.proposal().seats() + '</del>)</span>'
            return item.proposal().seats()
        })

        item.isArchivableUser = ko.computed(function () {
            var stateArray = ['canceled', 'annulled', 'denied', 'expired', 'claimed', 'missed']
            if (stateArray.includes(item.state()))
                return true
            if (item.state() === 'accepted') {
                return item.isAfterDate()
            }
            return false
        })

        item.isArchivableCatering = ko.computed(function () {

            var stateArray = ['canceled', 'annulled', 'denied', 'expired', 'claimed', 'missed'];
            return stateArray.includes(item.state());

        });

        item.stateLabel = ko.computed(function () {
            switch (item.state()) {
                case 'requested':
                    return "<span class=\"label label-danger blinking-button\">In attesa</span>";
                case 'accepted':
                    return "<span class=\"label label-success blinking-button\">Accettata</span>";
                case 'canceled':
                    return "<span class=\"label label-warning\">Cancellata</span>";
                case 'annulled':
                    return "<span class=\"label label-warning\">Annullata</span>";
                case 'denied':
                    return "<span class=\"label label-warning\">Rifiutata</span>";
                case 'missed':
                    return "<span class=\"label black-border text-primary\">No show</span>";
                case 'expired':
                    return "<span class=\"label label-black\" " +
                        "data-toggle=\"tooltip\" " +
                        "title=\"La Richiesta è scaduta perchè l'Attività non ha risposto in tempo utile\">" +
                        "Scaduta" +
                        "</span>";
                case 'proposed':
                    return "<span class=\"label label-danger blinking-button\">Controproposta</span>";
                case 'reviewed':
                    return "<span class=\"label label-grey\">Revisione</span>";
                case 'shared':
                    return "<span class=\"label label-grey\">Condivisa</span>";
                case 'claimed':
                    return "<span class=\"label success-border text-primary\">Completata</span>";
                case 'drafted':
                    return "<span class=\"label label-grey\">Bozza</span>";
                case 'pending':
                    return "<span class=\"label label-grey\">Pending</span>";
                default:
                    return item.state();
            }

        });

        item.stateTextLabel = ko.computed(function () {
            switch (item.state()) {
                case'requested':
                    return "<span class=\"label label-danger blinking-button\">Stato: In attesa</span>";
                case'accepted':
                    return "<span class=\"label label-success\">Stato: Accettata</span>";
                case'canceled':
                    return "<span class=\"label label-warning\">Stato: Cancellata</span>";
                case'annulled':
                    return "<span class=\"label label-warning\">Stato: Annullata</span>";
                case'denied':
                    return "<span class=\"label label-warning\">Stato: Rifiutata</span>";
                case'missed':
                    return "<span class=\"label label-black\">Stato: No show</span>";
                case'expired':
                    return "<span class=\"label label-black\" " +
                        "data-toggle=\"tooltip\" " +
                        "title=\"La Richiesta è andata scaduta perchè l'Attività non ha risposto in tempo utile\">Stato: " +
                        "Scaduta" +
                        "</span>";
                case'proposed':
                    return "<span class=\"label label-danger blinking-button\">Stato: Controproposta</span>";
                case'reviewed':
                    return "<span class=\"label label-grey\">Stato: Revisione</span>";
                case'shared':
                    return "<span class=\"label label-grey\">Stato: Condivisa</span>";
                case'claimed':
                    return "<span class=\"label label-success\">Stato: Completata</span>";
                case'drafted':
                    return "<span class=\"label label-grey\">Stato: Bozza</span>";
                default:
                    return item.state();
            }

        });

        item.serviceDate = ko.computed(function () {
            if (item.proposal() && item.proposal() !== 'drafted' && item.proposal().service_date())
                return item.proposal().service_date()
            return item.service_date()
        });

        item.serviceTime = ko.computed(function () {
            if (item.proposal() && item.proposal() !== 'drafted' && item.proposal().service_time())
                return item.proposal().service_time()
            return item.service_time()
        });

        item.tooltipBookingCode = ko.computed(function () {
            if (item.booking_code().indexOf('PTA') !== -1) {
                return "Prenotazione Tavolo"
            }
            if (item.booking_code().indexOf('PTO') !== -1) {
                return "Prenotazione Tavolo + Preordine"
            }
            if (item.booking_code().indexOf('ODE') !== -1) {
                return "Ordinazione a domicilio"
            }
            if (item.booking_code().indexOf('OTA') !== -1) {
                return "Prenotazione d'asporto"
            }
            if (item.booking_code().indexOf('PPH') !== -1) {
                return "prenotazione Chef/Catering"
            }
            if (item.booking_code().indexOf('PPO') !== -1) {
                return "prenotazione Chef/Catering + Preordine"
            }
            if (item.booking_code().indexOf('OST') !== -1) {
                return 'Ordine con spedizione'
            }
            return item.booking_code();
        });

        item.serviceLabel = ko.computed(function () {
            switch (item.service_type()) {
                case 'delivery':
                    return "<span class=\"label label-grey\">Domicilio</span>";
                case 'takeaway':
                    return "<span class=\"label label-grey\">Asporto / Acquisto</span>";
                case 'preparedHome':
                    return "<span class=\"label label-grey\">Chef / Catering</span>";
                case 'sentHome':
                    return "<span class=\"label label-grey\">Spediz.</span>";
                case 'table':
                    return "<span class=\"label label-grey\">Locale</span>";
                default:
                    return item.service_type();
            }

        });

        item.tagLabel = ko.computed(function () {
            var tag = '';
            if (item.service_type() === 'table' || item.service_type() === 'preparedHome') {
                if (item.items && item.items().length > 0)
                  tag += '<span class="label label-grey" ' +
                    'data-toggle="tooltip" ' +
                    'title="In questo Ordine/Prenotazione sono stati aggiunti ' + item.items().length + ' prodotti">' +
                    'Preordine' +
                    '</span>'
            }
            return tag
        });

        item.priceTotalDiscount = ko.computed(function () {
            var discount = item.subtotal() - item.price()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceDiscountPromotion = ko.computed(function () {
            var discount = item.discountPromotion()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceExtraDiscount = ko.computed(function () {
            var discount = item.extra_discount_price()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceDiscountCoupon = ko.computed(function () {
            var discount = item.discountCoupon()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.addressLabel = ko.computed(function () {
            if (item.service_type() === 'delivery') {
                return 'Indirizzo Consegna'
            }

            if (item.service_type() === 'sentHome') {
                return 'Indirizzo Spedizione'
            }

            if (item.service_type() === 'preparedHome') {
                return 'Indirizzo Servizio';
            }

            return 'Indirizzo';
        });

        item.servicePriceLabel = ko.computed(function () {
            if (item.service_type() === 'delivery') {
                return 'Consegna';
            }

            if (item.service_type() === 'sentHome') {
                return 'Spedizione';
            }

            if (item.service_type() === 'preparedHome') {
                return 'Servizio';
            }

            return 'Servizio';
        });

        item.paypalInfo = function () {
            Swal.fire({
                title: 'Informazioni PayPal',
                html: 'TrovaciGusto incassa online tramite il sistema PayPal, in nome e per conto della tua Attività, ' +
                    'quanto dovuto dal tuo Cliente, e ogni due settimane, se il totale a tuo credito ha superato i 200€,' +
                    ' altrimenti ogni mese, ti bonifica gli importi incassati, al netto delle commissioni ' +
                    'di prenotazione/ordinazione e di pagamento elettronico',
                icon: 'info',
                width: 800,
                confirmButtonText: 'Ok, ho capito',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                },
                buttonsStyling: false
            })
        }

        item.paymentLabel = ko.computed(function () {
            if (!item.transaction())
                return 'Non specificato';

            if (item.transaction().isOnlinePayed)
                return 'già pagato online con PayPal';

            if (item.service_type() === 'delivery' || item.service_type() === 'sentHome') {
                return 'Paga alla consegna';
            }

            if (item.service_type() === 'table') {
                return 'Paga nel locale';
            }

            if (item.service_type() === 'preparedHome') {
                return 'Paga di persona';
            }

            if (item.service_type() === 'takeaway') {
                return 'Di persona al ritiro';
            }

            return 'Non ppecificato';
        });

        item.calculatePaymentPrice = ko.pureComputed(function () {
            var paypal_price = 0.37;
            var paypal_fee = 3.6;
            if (item.price() <= 0) {
                return 0;
            }
            var extra = (item.price() * paypal_fee / 100);
            return Math.round((paypal_price + extra + Number.EPSILON) * 100) / 100
        });

        item.calculateTotalPrice = ko.pureComputed(function () {
            if (item.price() <= 0) {
                return 0;
            }
            return item.price() + (item.transaction().isOnlinePayed ? item.calculatePaymentPrice() : 0);
        })

        item.calculateTotalProposedPrice = ko.pureComputed(function () {
            if (!item.proposal())
                return 0
            if (item.proposal().price() <= 0) {
                return 0
            }
            return item.proposal().price()
        })

        item.calculateTotalProposedTotal = ko.pureComputed(function () {
            if (!item.proposal())
                return 0
            if (item.proposal().total() <= 0) {
                return 0
            }
            return item.proposal().total()
        })

        item.discountCouponTooltip = ko.pureComputed(function () {
            if (!item.coupon) {
                return ''
            }
            var text = 'Codice sconto: ' + item.coupon().name + '<br>'
            text += 'del valore di ' + item.coupon().discount + (item.coupon().type === 'percentage' ? '%' : '€')
            if (item.coupon().combinable)
                return text + ' viene applicato sul Totale'
            return text + ' viene applicato solo agli elementi che non sono già scontati'
        })

        item.discountPromotionTooltip = ko.pureComputed(function () {
            if (!item.discount_type()) {
                return ''
            }
            var text = 'La Proposta in Promozione del valore di  '
            text += item.discount() + (item.discount_type() === 'percentage' ? '%' : '€')
            if (item.is_total_discount())
                return text + ' viene applicato sul Totale Richiesta'
            return text + ' viene applicato solo agli elementi che non sono già scontati'
        })

        return item
    }
}