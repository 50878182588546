function BookingViewModel() {
    var self = this;

    self.bookingModule = new BookingModule();
    self.bookingCartModule = new CartModule(self.bookingModule);
    self.modalBookingCart = new BookingCartModal(self.bookingCartModule);
    self.modalBookingDrafts = new BookingDraftsModal(self.bookingModule);

    self.openCartDraft = function (item) {
        self.modalBookingCart.setCatering(item);
        self.modalBookingCart.show();
    }

    self.openFirstCartDraft = function () {
        if (self.bookingModule.bookingDrafts().length > 0 && self.bookingModule.bookingDrafts()[0]) {
            var draft = self.bookingModule.bookingDrafts()[0];
            location.href = draft.catering.bundle.seo + '?cart=' + draft.id;
        }
    }

    self.selectModal = function () {
        if (self.bookingModule.bookingDrafts().length === 1) {
            self.openCartDraft(self.bookingModule.bookingDrafts()[0].catering)
        } else
            self.modalBookingDrafts.show();
    }

  self.deleteDraft = function (itemId) {
    return self.bookingModule.deleteDraft(itemId)
      .then(function (response) {
        if (response.success) {
          self.bookingCartModule.cart(null)
          self.modalBookingCart.stepService()
        }
      })
  }

  window.onload = function () {
    self.bookingModule.loadDrafts()
  }

  return self
}


if (document.getElementById("booking-bind")) {
    var bookingVM = new BookingViewModel();
    ko.applyBindings(bookingVM, document.getElementById("booking-bind"));
}

