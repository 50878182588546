function BookingBillingModel(item) {
    var self = this;

    self.id = ko.observable(item ? item.id : null);
    self.number = ko.observable(item ? item.number : null);
    self.header = ko.observable(item ? item.header : null);
    self.vat_number = ko.observable(item ? item.vat_number : null);
    self.fiscal_code = ko.observable(item ? item.fiscal_code : null);
    self.pec = ko.observable(item ? item.pec : null);
    self.edocument_code = ko.observable(item ? item.edocument_code : null);
    self.state = ko.observable(item ? item.state : null);
    self.amount = ko.observable(item ? item.amount : null);
    self.tax_rate = ko.observable(item ? item.tax_rate : null);
    self.note = ko.observable(item ? item.note : null);
    self.direction = ko.observable(item ? item.direction : null);
    self.postal_code = ko.observable(item ? item.postal_code : null);
    self.state_id = ko.observable(item ? item.state_id : null);
    self.country_id = ko.observable(item ? item.country_id : null);
    self.province_id = ko.observable(item ? item.province_id : null);
    self.city_id = ko.observable(item ? item.city_id : null);
    self.booking_id = ko.observable(item ? item.booking_id : null);

    self.state_name = ko.observable(item && item.state_name ? item.state_name : '');
    self.city_name = ko.observable(item && item.city_name ? item.city_name : '');
    self.province_name = ko.observable(item && item.province_name ? item.province_name : '');
    self.country_name = ko.observable(item && item.country_name ? item.country_name : 'italia');

    return self;

}
