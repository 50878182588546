function BookingDraftsModal(bookingModule) {

    var self = new ModalModule();

    self.catering = ko.observable();
    self.user = ko.observable();
    self.details = ko.observable(false);
    self.modalSize('modal-md');

    self.bookingModule = bookingModule ? bookingModule : new BookingModule();

    self.headerLabel('<i class="fa fa-shopping-cart"></i> Tue Richieste da completare');
    self.bodyTemplate('bookingDraftsModalTemplate');
    self.headerTemplate('headerBookingDraftsTemplate');

    self.loading = ko.observable(false);

    self.show = function () {
        closeAllModal();
        self.modalVisible(true);
    };

    return self;
}