function CartModule (bookingModule) {
  var self = this

  self.cart = ko.observable(null)
  self.orders = ko.observable()
  self.cateringId = ko.observable()
  self.isFavorite = ko.observable(false)
  self.loading = ko.observable(false)
  self.loadingAdd = ko.observable(false)
  self.bookingAddressValid = ko.observable(false)
  self.bookingAddressChecked = ko.observable(false)
  self.billingDataValid = ko.observable(null)
  self.addressBookingVM = ko.observable(null)
  self.addressBillingVM = ko.observable(null)
  self.latestAddress = ko.observable(null)
  self.userAddress = ko.observable(null)
  self.bookingErrorReason = ko.observable()
  self.isBookable = ko.observable(false)
  self.noteVisible = ko.observable(false)
  self.serviceTimeRanges = ko.observableArray([])
  self.listOffers = ko.observableArray([])
  self.listDishes = ko.observableArray([])
  self.listDonations = ko.observableArray([])
  self.selectedDonationProgram = ko.observable()
  self.showTotalToPay = ko.observable(false)
  self.donationDetails = ko.observable(false)
  self.activeDonation = ko.observable(false)
  self.donationAmount = ko.observable(0.0)
  self.donationDescription = ko.observable('')
  self.selectedOffer = ko.observable(null)
  self.selectedDish = ko.observable(null)
  self.otherSeat = ko.observable(false)
  self.userCredits = ko.observable(0.0)
  self.paymentType = ko.observable('offline')
  self.couponName = ko.observable('')

  self.bookingModule = bookingModule ? bookingModule : new BookingModule()

  self.isEmpty = ko.computed(function () {
    if (!self.cart())
      return true
    if (self.cart().service_date())
      return false
    return !(self.cart().items && self.cart().items().length > 0)

  })

  self.messages = {
    'booking.service_type': 'Seleziona una modalità di servizio',
    'booking.service.unsupported': 'Modalità di servizio non più disponibile, selezionane un\'altra',
    'booking.datetime': 'Non sono state trovate date disponibili per gli elementi che hai aggiunto alla tua Richiesta. Ricontrolla le disponibilità e le modalità di servizio',
    'booking.service_date': 'Per procedere devi indicare la data',
    'booking.service_time': 'Per procedere devi indicare l\'orario',
    'booking.seats': 'Per procedere, devi indicare e/o confermare il numero di persone',
    'promotion.minPeople': 'Per usufruire dello sconto alla cassa (servizio al tavolo) è richiesto un numero minimo di posti prenotati',
    'offer.minPeople': 'Una delle proposte che hai scelto deve essere richiesta in quantità maggiore',
    'offer.daily': 'Una delle proposte che hai scelto eccede la quantità disponibile o è terminata nel giorno che hai selezionato.',
    'offer.amount': 'Una delle proposte che hai scelto non è disponibile nella quantità indicata. Prova a diminuirla',
    'booking.too-late': 'L\'orario che hai scelto è troppo ravvicinato o è già passato',
    'booking.items.empty': 'Per procedere con questa Richiesta devi aggiungere elementi alla Richiesta',
    'booking.items.dirty': 'Siamo spiacenti, ma uno o più elementi che hai inserito nella tua Richiesta non sono validi. Per continuare, rimuovilo/i.',
    'item.unavailable': 'Siamo spiacenti, ma uno degli elementi che hai inserito nella tua Richiesta non è più disponibile',
    'item.dirty': 'L\'elemento è stato Aggiunto alla Richiesta di Prenotazione | Ordine ma presenta delle incompatibilità. controlla il messaggio riportato nel dettaglio',
    'offer.service.unsupported': 'Uno degli elementi nella tua Richiesta non è disponibile per il tipo di servizio che hai scelto',
//  'booking.delivery.distance': "Attenzione: questo locale non effettua la consegna presso l'indirizzo da te indicato."
    'booking.address': 'Conferma l\'indirizzo prima di procedere con questa Richiesta',
    'booking.guest': 'Accedi e verifica la tua email per confermare',
    'booking.account-not-active': 'Accedi e verifica la tua email per confermare',
  }

  self._serviceTimeRanges = [
    {label: 'Notte', start: '00:00', end: '04:00'},
    {label: 'Mattina', start: '04:01', end: '10:30'},
    {label: 'Giorno', start: '10:31', end: '15:30'},
    {label: 'Pomeriggio', start: '15:31', end: '17:30'},
    {label: 'Sera', start: '17:31', end: '22:30'},
    {label: 'Tarda sera', start: '22:31', end: '24:00'}
  ]

  self.startDate = ko.computed(function () {
    if (self.cart() && self.cart().dates) {
      return moment.unix(self.cart().dates.dateStart).toDate()
    }
    return new Date()
  })

  self.serviceTimes = ko.pureComputed(function () {

    if (!self.cart().service_date()) {
      return null
    }

    if (!self.cart().dates) {
      return null
    }

    self.serviceTimeRanges([])

    // We'll need a temporary array to store the time ranges,
    // and another array to keep memory of those ranges
    // that we need to show. For example, when range 1, 2, 4 and 6
    // actually contain a valid time, the indexes array would be
    // something like [0, x, y, 0, k, 0, j], where every variable
    // is greater than zero (it will be the number of occurrence,
    // even if we don't need that value).
    var temp = self._serviceTimeRanges.slice(0)
    var indexes = [0, 0, 0, 0, 0, 0]

    // Since we assumed a day change has occurred, we need the
    // day-of-week index. We store this in the 'i' var.
    var theServiceDate = moment.unix(self.cart().service_date())
    var i = theServiceDate.isoWeekday()

    if (!self.cart().dates.schedule || !self.cart().dates.schedule[i]) {
      return null
    }
    var dailySchedule = self.cart().dates.schedule[i]

    var whatDayIsIt = moment().format('YYYY MM DD')
    //quanti minuti prima della prenotazione
    var previousMinutes = 15
    if (self.cart().service_type() && self.cart().service_type() === 'delivery')
      previousMinutes = 30
    if (self.cart().service_type() && self.cart().service_type() === 'table')
      previousMinutes = 5

    var whatTimeIsIt = moment().add(previousMinutes, 'm').format('HH:mm')

    // We now need to find which ranges in the self._serviceTimeRanges array
    // we want to show in the UI (keep in mind that self._serviceTimeRanges has
    // been copied in the temp array). For this purpose we need to compare
    // each allowed time to the self._serviceTimeRanges limits (start, end).

    var dailyScheduleClean = []
    for (var j in dailySchedule) {

      // Skip times that are already past.
      if (theServiceDate.format('YYYY MM DD') === whatDayIsIt && whatTimeIsIt >= dailySchedule[j]) {
        continue
      }

      dailyScheduleClean.push(dailySchedule[j])
      for (var k in temp) {
        var range = temp[k]

        if (dailyScheduleClean[dailyScheduleClean.length - 1] >= range.start && dailySchedule[j] <= range.end) {
          indexes[k]++
        }
      }
    }

    for (k in indexes) {
      // If this index contains atleast one entry we have to push it in the final array.
      if (indexes[k]) {
        self.serviceTimeRanges.push(temp[k])
      }
    }

    return dailyScheduleClean

  })

  self.message = ko.computed(function () {
    if (!self.cart || !self.cart()) {
      return ''
    }
    if (self.bookingErrorReason() === 'booking.delivery.priceMin') {
      return 'Il totale minimo della Richiesta per la consegna è ' + self.cart().catering.serviceDeliveryPriceMin + ' ' + '\u20AC'
    }
    return self.messages[self.bookingErrorReason()]
  })

  self.note = ko.computed(function () {
    return self.noteVisible() || (self.cart() && self.cart().note() && self.cart().note().length)
  })

  self.hasConflictDate = ko.computed(function () {
    return self.cart() && self.cart().service_type() && (!self.cart().dates || !self.cart().dates.schedule)
  })

  self.addressLabel = ko.computed(function () {
    if (!self.cart())
      return 'Indirizzo'

    if (self.cart().service_type() === 'delivery') {
      return 'Indirizzo Consegna'
    }

    if (self.cart().service_type() === 'sentHome') {
      return 'Indirizzo Spedizione'
    }

    if (self.cart().service_type() === 'preparedHome') {
      return 'Indirizzo Servizio'
    }

    return 'Indirizzo'
  })

  self.offersDefaultOption = ko.pureComputed(function () {
    if (self.listOffers().length <= 0)
      return 'Non ci sono Proposte e Offerte disponibili'
    return 'Seleziona Proposte e Offerte'
  })

  self.showAllCateringOffers = function () {
    var url = self.cart().catering.bundle.seo + '#tab-offers?r=' + (+new Date())
    window.history.pushState("", "", url);
    location.replace(url)
    window.location.reload()
  }

  self.showAllCateringDishes = function () {
    var url = self.cart().catering.bundle.seo + '#tab-menu?r=' + (+new Date())
    window.history.pushState("", "", url);
    location.replace(url)
    window.location.reload()
  }

  self.calculatePaymentPrice = ko.computed(function () {
    var paypal_price = 0.37
    var paypal_fee = 3.6
    if (!self.cart() || self.cart().price() <= 0) {
      return 0
    }
    var extra = (self.cart().price() * paypal_fee / 100)
    return Math.round((paypal_price + extra + Number.EPSILON) * 100) / 100
  })

  self.calculateTotalPrice = ko.computed(function () {
    if (!self.cart() || self.cart().price() <= 0) {
      return 0
    }
    return self.cart().price() + (self.paymentType() !== 'online' ? 0 : self.calculatePaymentPrice())
  })

  self.usableCreditsTextLabel = ko.pureComputed(function () {
      return "Hai " + self.userCredits().toFixed(2) + "€ in FanCredits: vuoi usarli per ridurre il totale da pagare?"
  })

  self.usableCreditsText = ko.pureComputed(function () {
    var credit = self.userCredits()
    if (self.cart().extra_discount_price() > 0) {
      if (self.userCredits() >= self.cart().extra_discount_price())
        credit = self.userCredits() - self.cart().extra_discount_price()
      return 'Hai utilizzato ' + self.cart().extra_discount_price() + '€ dei tuoi FanCredits. Hai a disposizione ancora ' + credit + '€'
    }

    if (self.userCredits() >= self.cart().price())
      credit = self.cart().price() + self.cart().extra_discount_price()
    if (self.cart().subtotal > 0)
      return 'Puoi utilizzare ' + credit.toFixed(2) + '€ dei tuoi FanCredits disponibili'

    return 'Per usarli, ti basta aggiungere <a href="' + self.cart().catering.bundle.seo + 'r=' + (+new Date()) + '#tab-offers">Proposte, Offerte</a> ' +
        'e/o <a href="' + self.cart().catering.bundle.seo + 'r=' + (+new Date()) + '#tab-menu">Voci di ' + self.cart().catering.menuOrList + '</a> ' +
        'alla tua Richiesta prima di inviarla'

  })

  self.priceTotalDiscount = ko.pureComputed(function () {
    var discount = self.cart().subtotal() - self.cart().price()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceDiscountPromotion = ko.pureComputed(function () {
    var discount = self.cart().discountPromotion()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceExtraDiscount = ko.pureComputed(function () {
    var discount = self.cart().extra_discount_price()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceDiscountCoupon = ko.pureComputed(function () {
    var discount = self.cart().discountCoupon()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.discountCouponTooltip = ko.pureComputed(function () {
    if (!self.cart().coupon) {
      return ''
    }
    var text = 'Codice sconto: ' + self.cart().coupon().name + '<br>'
    text += 'del valore di ' + self.cart().coupon().discount + (self.cart().coupon().type === 'percentage' ? '%' : '€')
    if (self.cart().coupon().combinable)
      return text + ' viene applicato sul Totale'
    return text + ' viene applicato solo agli elementi che non sono già scontati'
  })

  self.servicePriceLabel = ko.pureComputed(function () {
    if (self.cart().service_type() === 'delivery') {
      return 'Consegna'
    }

    if (self.cart().service_type() === 'sentHome') {
      return 'Spedizione'
    }

    if (self.cart().service_type() === 'preparedHome') {
      return 'Servizio'
    }

    return 'Servizio'
  })

  self.activeDonation.subscribe(function (value) {
    if (!value) {
      self.paymentType('offline')
      self.disapplyDonation()
    } else {
      self.paymentType('online')
    }
  })

  self.toggleCredits = function () {
    if (self.cart().extra_discount_price() <= 0)
      self.setCredits()
    else
      self.unsetCredits()
  }

  self.selectedDonationProgram.subscribe(function (item) {
    if (item && self.activeDonation()) {
      self.loading(true)
      return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/donations', { 'id': item })
        .then(function (response) {
          if (response.success) {
            self.donationDescription(response.data.donationProgram.description)
            self.update()
          } else {
            if (response.message)
              pNotify(response.message, 'danger')
          }
          self.loading(false)
        })
        .catch(function () {
          self.loading(false)
          pNotify('Non è stato possibile applicare la donazione', 'danger')
        })
      }
    }
  )

  self.donationAmount.subscribe(function (item) {
    if (item && self.activeDonation()) {
      if (self.cart().donation() && item !== self.cart().donation().amount)
        self.applyDonationAmount(item)
    }
  })

  /**
   * read/write booking service date
   */
  self.dateTimepicker = ko.pureComputed({
    read: function () {
      return self.cart().service_date() ? moment.unix(self.cart().service_date()).toDate() : null
    },
    write: function (v) {
      self.cart().service_date(v ? moment(v).format('X') : null)
      self.cart().service_time(null)
      self.update()
    }
  })

  self.addressChanged = ko.computed(function () {
    if (!self.addressBookingVM() || !self.addressBookingVM().address)
      return
    if (!self.addressBookingVM().addressComplete() || self.addressBookingVM().address.latitude() || self.addressBookingVM().address.longitude()) {
      self.bookingAddressValid(false)
      self.bookingAddressChecked(false)
    }
  })

  self.setLatestAddress = function () {
    self.addressBookingVM(new AddressModule(self.latestAddress()))
    self.confirmAddressBooking()
  }

  self.setUserAddress = function () {
    self.addressBookingVM(new AddressModule(self.userAddress()))
    self.confirmAddressBooking()

  }

  self.printDeliveryInfo = ko.computed(function () {
    var infoArray = []
    if (self.cart() && self.cart().catering) {

      if (self.cart().catering.serviceDelivery && self.cart().service_type() === 'delivery') {
        if (self.cart().catering.serviceDeliveryAverageTime > 0) {
          infoArray.push('Consegna media in ' + self.cart().catering.serviceDeliveryAverageTime + ' min')
        }
        if (self.cart().catering.serviceDeliveryPriceMin > 0) {
          infoArray.push('Spesa minima per la consegna ' + self.cart().catering.serviceDeliveryPriceMin + '€')
        }
        if (self.cart().catering.serviceDeliveryFreeThreshold > 0) {
          infoArray.push('Consegna gratuita per importi superiori a ' + self.cart().catering.serviceDeliveryFreeThreshold + '€')
        }
        if (self.cart().catering.serviceDeliveryPrice > 0) {
          infoArray.push('Costo della consegna ' + self.cart().catering.serviceDeliveryPrice + '€')
        } else {
          infoArray.push('Consegna gratuita')
        }
        if (self.cart().catering.ranges && self.cart().catering.ranges[0]) {
          infoArray.push('Raggio di consegna ' + self.cart().catering.ranges[0].distance + ' Km')
        }
        if (self.cart().catering.serviceDeliveryInfo !== '') {
          infoArray.push('Info: ' + self.cart().catering.serviceDeliveryInfo)
        }
      }

    }

    return infoArray.join(' | ')
  })

  self.printSentInfo = ko.computed(function () {
    var infoArray = []
    if (self.cart() && self.cart().catering) {

      if (self.cart().catering.serviceSentHome && self.cart().service_type() === 'sentHome') {
        if (self.cart().catering.serviceSentPriceMin > 0) {
          infoArray.push('Spesa minima per la spedizione ' + self.cart().catering.serviceSentPriceMin + '€')
        }
        if (self.cart().catering.serviceSentFreeThreshold > 0) {
          infoArray.push('Spedizione gratuita per importi superiori a ' + self.cart().catering.serviceSentFreeThreshold + '€')
        }
        if (self.cart().catering.serviceSentPrice > 0) {
          infoArray.push('Costo della spedizione ' + self.cart().catering.serviceSentPrice + '€')
        } else {
          infoArray.push('Spedizione gratuita')
        }
        if (self.cart().catering.serviceSentInfo !== '') {
          infoArray.push('Info: ' + self.cart().catering.serviceSentInfo)
        }
      }
    }

    return infoArray.join(' | ')
  })

  self.printMessageFreeThreshold = ko.computed(function () {
    var threshold = 0
    if (self.cart() && self.cart().catering) {
      if (self.cart().catering.serviceSentHome && self.cart().service_type() === 'sentHome') {
        threshold = self.cart().catering.serviceSentFreeThreshold - self.cart().subtotal()
        if (threshold > 0) {
          return 'Ti mancano solo ' + threshold + '€ per ottenere la spedizione gratuita.'
        }
      }
      if (self.cart().catering.serviceDelivery && self.cart().service_type() === 'delivery') {
        threshold = self.cart().catering.serviceDeliveryFreeThreshold - self.cart().subtotal()
        if (threshold > 0) {
          return 'Ti mancano solo ' + threshold + '€ per ottenere la consegna gratuita.'
        }
      }
    }
    return ''
  })

  self.optionSelectPrintOffer = function (item) {
    if (item.type === 'recipe' || item.type === 'post') {
      return item.name
    }
    var optionName = item.name + ' | '
    if (item.price > 0) {
      if (item.discountMode === 'percentage' && item.discount > 0) {

        return optionName + item.price + '€' + '  (' + item.priceMax + '€ -' + item.discount + '%)'
      }
      if (item.discountMode === 'currency' && item.discount > 0) {
        return optionName + item.price + '€' + '  (' + item.priceMax + '€ -' + item.discount + '€)'
      }

      if (item.discount === 0) {
        return optionName + item.price + '€'

      }
    }

    if (item.price === 0) {
      if (item.discountMode === 'percentage' && item.discount > 0)
        return optionName + '-' + item.discount + '%'

      if (item.discountMode === 'currency' && item.discount > 0) {
        return optionName + '-' + item.discount + '€'
      }

      if (item.discount === 0) {
        if (item.type === 'promotion-gift')
          return optionName + 'Omaggio'
        if (item.type !== 'promotion-gift')
          return optionName + 'Gratis'
      }
    }
  }

  self.userCreditsInfo = function () {
    Swal.fire({
        html: '<p class="text-md">Puoi usare i tuoi FanCredits per ottenere una riduzione dell’importo totale che pagherai (online o di persona, in base alle opzioni di pagamento accettate dall’Attività, e a quella da te selezionata).<br><br>Qualora Proposte, Offerte e/o Voci di '
          + self.cart().catering.menuOrList
            + ' da te effettivamente fruite al momento del servizio dovessero cambiare, per differenti accordi tra te e il Gestore dell’Attività food (ad es. perché una Voce di '
          + self.cart().catering.menuOrList
            + ' non risulta più disponibile) avrai diritto alla medesima riduzione dell’eventuale nuovo importo totale da pagare, ovvero una riduzione pari alla quantità di FanCredits qui utilizzati in fase di invio della Richiesta.</p>',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-success mg-r-xs',
      },
      buttonsStyling: false
    })
  }

  self.optionSelectPrintDish = function (item) {
    var optionName = item.name + ' | '
    if (item.price_sale > 0) {
      if (item.discount > 0) {

        return optionName + item.price_sale + '€' + ' (' + item.price + '€ -' + item.discount + '%)'
      }
      if (item.discount === 0) {
        return optionName + item.price_sale + '€'

      }
    }

    if (item.price_sale === 0) {
      if (item.isPriceHidden) {
        return optionName + 'Prezzo su richiesta'
      }
      if (item.discount > 0) {
        return optionName + '-' + item.discount + '%'
      }
      return optionName + 'Gratis'
    }
    return item.name
  }

  self.toggleNote = function () {
    if (self.noteVisible()) {
      self.noteVisible(false)
    } else {
      self.noteVisible(true)
    }
  }

  self.otherSeatToggle = function () {
    if (!self.otherSeat()) {
      self.otherSeat(true)
      self.setSeats(self.cart().seats() ? self.cart().seats() : 0)
    } else {
      self.otherSeat(false)
      self.setSeats(0)
    }
  }

  self.loadLatestAddresses = function () {
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/addresses')
        .then(function (response) {
          if (response.success) {
            self.latestAddress(response.data.latestAddress)
            self.userAddress(response.data.userAddress)
          }
          return response
        })
  }

  self.loadByCatering = function (create) {
    if (!self.cart() || self.cart().catering_id() !== self.cateringId()) {
      self.cart(null)
      self.loading(true)

      return rest('GET', '/api/v2/bookings/cart/catering/' + self.cateringId() + (create ? '/?create=1' : ''))
        .then(function (response) {
          if (!response.success) {
            self.bookingErrorReason(response.status)
            self.isBookable(false)
          } else {
            self.bookingErrorReason('')
            self.isBookable(true)
          }
          if (response.data)
            self.setCart(response.data)
          self.loading(false)
          return response
        })
        .then(function () {
          bookingVM.bookingModule.loadDrafts()
        })
        .catch(function (response) {
          self.loading(false)
          return response
        })
    }
    return Promise.resolve({ state: 'success', data: self.cart() })
  }

  self.loadDraftById = function (cartId) {
    if (!cartId) {
      return
    }
    self.loading(true)

    return rest('GET', '/api/v2/bookings/cart/' + cartId)
      .then(function (response) {
        if (!response.success) {
          self.bookingErrorReason(response.status)
          self.isBookable(false)
        } else {
          self.bookingErrorReason('')
          self.isBookable(true)
        }
        if (response.data) {
          self.setCart(response.data)
          self.cateringId(response.data.catering_id)
        }
        self.loading(false)
        return response
      })
      .then(function (response) {
        bookingVM.bookingModule.loadDrafts()
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadCateringOffers = function () {
    self.loading(true)
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/offers')
      .then(function (response) {
        if (response.success)
          self.listOffers(response.data)
        else
          self.listOffers([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadCateringDishes = function () {
    self.loading(true)
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/dishes')
      .then(function (response) {
        if (response.success)
          self.listDishes(response.data)
        else
          self.listDishes([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadUserCredits = function () {
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
      .then(function (response) {
        if (response.success)
          self.userCredits(response.data)
        return response
      })
      .catch(function (response) {
        self.userCredits(0.0)
        return response
      })

  }

  self.setItemAmount = function (item, amount) {
    if (!item || amount === undefined) {
      return
    }
    var data = {
      item: item,
      amount: amount >= 0 ? amount : 0
    }
    self.loading(true)
    return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/amount', data)
      .then(function (response) {
        if (response.success && response.data.item)
          pNotify('La quantità di ' + response.data.item.name + ' è stata modificata')
        self.applyResponseBooking(response)
        self.loading(false)
      })
        .catch(function (err) {
          self.loading(false)
        })
  }

  self.checkServiceRequiresAddress = function () {
    var services = ['delivery', 'sentHome', 'preparedHome']

    return services.includes(self.cart().service_type())
  }

  self.applyCoupon = function () {
    return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/coupons', { 'name': self.couponName })
      .then(function (response) {
        if (response.success)
          self.update()
        else {
          if (response.message)
            pNotify(response.message, 'danger')
        }
      })
      .catch(function () {
        pNotify('Non è stato possibile applicare il coupon', 'danger')
      })
  }

  self.disapplyCoupon = function () {
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/coupons/')
      .then(function (response) {
        if (response.success)
          self.update()
      }).catch(function (err) {

      })
  }

  self.applyDonationAmount = function (value) {
    return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/donations', {
      'id': self.selectedDonationProgram(),
      'amount': value
    })
      .then(function (response) {
        if (response.success) {
          if (response.status === 'min_amount')
            pNotify('Limporto minimo di questa donazione è: ' + response.data.donationProgram.min_amount + '€', 'warning')
          self.update()
        } else {
          if (response.message)
            pNotify(response.message, 'danger')
        }
      })
      .catch(function () {
        pNotify('Non è stato possibile applicare la donazione', 'danger')
      })
  }

  self.disapplyDonation = function () {
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/donations/')
      .then(function (response) {
          if (response.success) {
            self.donationAmount(0.0)
            self.donationDescription('')
            self.selectedDonationProgram(null)
            self.update()
          }
        }
      ).catch(function (err) {

      })
  }

  self.loadDonationPrograms = function () {
    self.loading(true)
    return rest('GET', '/api/v2/donation_programs')
      .then(function (response) {
        if (response.success)
          self.listDonations(response.data)
        else
          self.listDonations([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })
  }

  self.setCart = function (data) {

    self.cart(new BookingModel(data))
    if (self.checkServiceRequiresAddress() && !self.addressBookingVM()) {
      self.setAddressBooking()
    }
    /*    if (!self.addressBookingVM())//@todo caricare solo quando necessario
          self.setAddressBilling()*/

    ko.utils.arrayForEach(self.cart().items(), function (item) {
      item.amount.subscribe(function (v) {
        if (v >= 0) {
          self.setItemAmount(item, v)
        }
      })
    })

    if (self.cart().seats() > 10)
      self.otherSeat(true)

    if (self.cart().coupon())
      self.couponName(self.cart().coupon().name)

    if (self.cart().donation()) {
      self.donationAmount(self.cart().donation().amount)
      self.selectedDonationProgram(self.cart().donation().donation_id)
      self.activeDonation(true)
    }

    if (self.cart().dirty()) {
      pNotify('Attenzione: l\'Attività ha apportato delle modifiche, ricontrolla tutte le informazioni della Richiesta prima di inviarla!', 'warning')

    }
  }

  self.setItemNote = function (item) {
    Swal.fire({
      input: 'textarea',
      customClass: {
        container: 'swal-modal'
      },
      inputValue: item.note() ? item.note() : '',
      inputPlaceholder: 'Scrivi una nota!',
      inputAttributes:
        {
          'aria-label':
            'Scrivi una nota!'
        }
      ,
      showCancelButton: true
    }).then(function (result) {
      if (result.value) {
        var data = {
          item: item,
          note: result.value
        }

        return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/note', data)
            .then(function (response) {
            item.note(result.value)
          })

      }
    })
  }

  self.update = function () {
    if (!self.cart())
      return $.Deferred().fail()

    self.loading(true)
    return rest('PUT', '/api/v2/bookings/' + self.cart().id(), self.cart)
      .then(function (response) {
        if (!response.data.booking)
          response.data.booking = response.data
        self.applyResponseBooking(response)
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })
  }

  self.applyResponseBooking = function (response) {
    if (!response.success) {
      self.bookingErrorReason(response.status)
      self.isBookable(false)
    } else {
      self.bookingErrorReason('')
      self.isBookable(true)
    }
    self.setCart(response.data.booking)
  }

  self.reload = function () {
    return self.loadByCatering()
  }

  self.toggleService = function (service) {
    if (self.cart().service_type() !== service) {
      self.resetDateTime()
    }
    self.setService(service)
  }

  self.resetDateTime = function () {
    self.cart().service_date(null)
    self.cart().service_time(null)
  }

  self.setSelectedTime = function (value) {
    if (self.cart() && self.cart().service_time)
      self.cart().service_time(value)

    self.update()

  }

  self.addItemById = function (itemId, itemType, amount) {
    self.loadingAdd(true)
    if (!itemId || !itemType || !self.cart() || !self.cart().id()) {
      pNotify('Impossibile aggiungere questo Prodotto alla Richiesta', 'error')
      self.loading(false)
      return Promise.reject()
    }

    var data = {
      id: itemId,
      type: itemType,
      amount: amount ? amount : 1
    }
    return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/', data)
      .then(function (response) {
        self.update()
        return response
      })
      .catch(function (response) {
        console.log(response)
        return response
      })
      .always(function () {
        sendFacebookEvent('track', 'AddToCart', { item_id: itemId, type: itemType })
        self.loadingAdd(false)
      })
  }

  self.addItemFromSelectOffer = function () {
    if (self.selectedOffer() > 0)
      return self.addItemById(self.selectedOffer(), 'offer')
        .then(function () {
          pNotify('La Proposta è stata aggiunta alla richiesta', 'success')
        })
    else {
      pNotify('Seleziona una Proposta', 'error')
    }
  }

  self.addItemFromSelectDish = function () {
    if (self.selectedDish() > 0)
      return self.addItemById(self.selectedDish(), 'dish')
        .then(function () {
          pNotify('La voce è stata aggiunta alla richiesta', 'success')
        })
    else {
      pNotify('Seleziona una voce', 'error')
    }
  }

  self.deleteItem = function (item) {
    if (!item) {
      pNotify('Errore durante al rimozione dell\'elemento. prova a ricaricare la pagina', 'error')
      return

    }
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/items', item).then(function (response) {
      if (response.success)
        pNotify(response.data.name + '  rimosso dalla tua Richiesta')
      else
        pNotify(self.messages[response.status], 'error')

      self.update()
    })
  }

  self.setAddressBooking = function () {
    if (!self.addressBookingVM()) {
      if (self.cart().address()) {
        self.addressBookingVM(new AddressModule(self.cart().address()))
        self.confirmAddressBooking()
        return
      }

      if (trovacigusto.userLogged()) {
        rest('GET', '/api/v2/users/' + trovacigusto.userLogged().id + '/address?type=shipping')
          .then(function (response) {
            self.addressBookingVM(new AddressModule(response.data))
            self.confirmAddressBooking()
          })
      } else {
        rest('GET', '/api/v2/guests/address').then(function (response) {
          self.addressBookingVM(new AddressModule(response.data))
          self.confirmAddressBooking()
        })
      }
    } else {
      self.confirmAddressBooking()
    }
  }

  self.setAddressBilling = function () {
    if (self.cart() && self.cart().billing)
      var address = new AddressModule(ko.mapping.toJS(self.cart().billing))
    else
      address = new AddressModule()

    self.addressBillingVM(address)
  }

  self.setService = function (service) {
    if (self.checkServiceRequiresAddress()) {
      self.setAddressBooking()
    }

    if (self.cart() && self.cart().service_type !== service) {
      self.cart().service_type(service)
      self.update()
    }

  }

  self.testDatesValid = function (date) {
    if (!self.cart() || !self.cart().dates || self.cart().dates.length <= 0) {
      return false
    }

    var momentTime = moment.tz(date, 'Europe/Rome')

    var time = momentTime.format('X')

    if (time > self.cart().dates.dateEnd) {
      console.log(date + ' >')
      return false
    }

    for (var i in self.cart().dates.schedule) {
      if (parseInt(i) === parseInt(momentTime.isoWeekday())) {
        return true
      }
    }
    return false
  }

  self.setCredits = function () {
    if (self.userCredits() > 0 && self.cart().extra_discount_price() <= 0) {
      return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
        .then(function (response) {
          if (response.success) {
            self.setCart(response.data)
          }
        })
        .catch(function (response) {

          return response
        })

    }
  }

  self.unsetCredits = function () {
    if (self.cart().extra_discount_price() > 0 && self.cart().extra_discount_reason() === 'creditsUsage') {
      return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
        .then(function (response) {
          if (response.success) {
            self.setCart(response.data)
          }
        })
        .catch(function (response) {
          return response
        })
    }
  }

  self.confirmAddressBooking = function () {
    if (!self.addressBookingVM() || !self.addressBookingVM().addressComplete()) {
      return
    }

    var data = {
      address: self.addressBookingVM().address,
      userId: trovacigusto.userLogged() ? trovacigusto.userLogged().id : null,
      cateringId: self.cateringId(),
    }
    self.bookingAddressChecked(false)
    rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/address/', data)
      .then(function (response) {
        if (!response.success) {
          self.bookingAddressValid(false)
        } else {
          self.bookingAddressValid(true)
        }
        self.bookingAddressChecked(true)
        self.update()
      })
  }

  self.confirmBillingData = function () {
    var data = {
      address: self.addressBillingVM().address,
      userId: trovacigusto.userLogged() ? trovacigusto.userLogged().id : null,
      cateringId: self.cateringId(),
      billing: self.cart().billing
    }

    rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/billing/', data)
      .then(function (response) {
        if (response.success) {
          pNotify('I dati di fatturazione sono corretti.')
        } else {
          pNotify('I dati di fatturazione non sono corretti. Controlla tutti i campi', 'warning')
        }
        self.billingDataValid(response.success)
      }).then(function () {

    })
  }

  self.setSeats = function (number) {
    if (self.cart()) {
      if (!isNaN(number)) {
        self.cart().seats(number)
      }
      self.update()
    }
  }

  self.requestBooking = function () {
    return self.update()
      .then(function (response) {
        if (response.success) {
          self.loading(true)
          return self.bookingModule.requested(self.cart().id())
            .then(function (response) {
              self.bookingModule.notifyResponse(response)
              self.loading(false)
              sendFacebookEvent('track', 'Purchase', { currency: 'EUR', value: self.cart().price() })
              return response
            })
            .catch(function (response) {
              self.loading(false)
              return response
            })
        }
        return response
      })
      .catch(function (response) {
        return response
      })
  }

  self.addFavoriteCatering = function () {
    if (!trovacigusto.userLogged()) {
      trovacigusto.modalLogin.headerLabel('Già Utente?<br>Accedi per diventare Fan')
      trovacigusto.modalLogin.show()
      return
    }

    trovacigusto.flagVM.store('catering', self.cateringId()).then(function (response) {
      if (response.success) {
        pNotify('Ora sei Fan di ' + response.data.name)
        self.isFavorite(true)
        self.update()
        return
      }
      if (response.status === 'flag.exist') {
        self.update()
      } else
        pNotify('C\'è stato un problema!', 'error')
    })
  }

}