function BookingModule () {
  var self = this

  self.processing = ko.observable(false)
  self.booking = ko.observable(null)
  self.bookingDrafts = ko.observableArray([])

  self.loadDrafts = function () {
    self.processing(true)
    return rest('GET', '/api/v2/users/bookings/drafted/')
        .then(function (response) {
            self.bookingDrafts(response.success ? response.data : [])
        })
        .catch(function () {
            self.bookingDrafts([])
        })
        .always(function () {
            self.processing(false)
        })
  }

  self.requested = function (itemId) {
    var data = {
      state: 'requested'
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.deleteDraft = function (itemId) {
    self.processing(true)
    return rest('DELETE', '/api/v2/bookings/cart/' + itemId)
      .then(function (response) {
        if (response.success) {
          self.bookingDrafts.remove(function (item) {
            return item.id === itemId
          })
          return response
        } else {
          self.notifyError()
        }
      })
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.accepted = function (itemId, message) {
      //@TODO inserire messagio nel caso stato proposed
      var data = {
          state: 'accepted',
          details: message ? message : ''
      }
      self.processing(true)
      return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
          .catch(function () {
              self.notifyError()
          })
          .always(function () {
              self.processing(false)
          })
  }

  self.denied = function (itemId, reason) {
    var data = {
      state: 'denied',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})

  }

  self.canceled = function (itemId, reason) {
    var data = {
      state: 'canceled',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.annulled = function (itemId, reason) {
    var data = {
      state: 'annulled',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.claimed = function (itemId) {
    var data = {
      state: 'claimed',
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.declareSeats = function (itemId, seats) {
    var data = {
      seats: seats,
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/seats', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.missed = function (itemId, reason) {
    var data = {
      state: 'missed',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', data)
      .catch(function (response) {
        self.notifyError()
      })
  }

  self.proposed = function (itemId) {
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/state', { state: 'proposed' })
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.proposalDenied = function (itemId, reason) {
    var data = {
      state: 'denied',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/proposals/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})

  }

  self.proposalAnnulled = function (itemId, reason) {
    var data = {
      state: 'annulled',
      details: reason
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/proposals/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})

  }

  self.proposalAccepted = function (itemId) {
    var data = {
      state: 'accepted'
    }
    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId + '/proposals/state', data)
      .catch(function () { self.notifyError()})
      .always(function () {self.processing(false)})
  }

  self.createPayment = function (itemId, paymentId) {
    return axios.post('/api/v2/bookings/' + itemId + '/transactions/payment/create',
      {
        payment_id: paymentId
      }
    )
  }

  self.authorizePayment = function (itemId) {
    return axios.get('/api/v2/bookings/' + itemId + '/transactions/payment/authorize')
  }

  self.confirmPayment = function (itemId) {
    return axios.get('/api/v2/bookings/' + itemId + '/transactions/payment/payed')
  }

  self.archived = function (itemId, type, value) {
    var data = {}
    if (type === 'user')
      data.user_archived = value
    if (type === 'catering') {
      data.catering_archived = value
    }

    self.processing(true)
    return rest('PUT', '/api/v2/bookings/' + itemId, data)
        .catch(function (response) {
          self.notifyError(response)
        })
        .always(function (response) {
          self.processing(false)
        })
  }

  self.notifyResponse = function (response) {
    if (!response || (!response.status && !response.message)) {
      return
    }

    if (response.data && response.data.booking) {
      self.booking(response.data.booking)
    }

    if (response.success) {
      switch (response.status) {
        case 'booking.create':
          pNotify('La Richiesta è stata creata')
          break
        case 'booking.denied':
          pNotify('La Richiesta è stata rifiutata')
          break
        case 'booking.annulled':
          pNotify('La Richiesta è stata annullata')
          break
        case 'booking.canceled':
          pNotify('La Richiesta è stata annullata')
          break
        case 'booking.completed':
          pNotify('La Richiesta è stata completata')
          break
        case 'booking.archived':
          pNotify('La Richiesta è stata archiviata')
          break
        case 'booking.accepted':
          pNotify('La Richiesta è stata accettata')
          break
      }
      return
    }
    if (response.message && response.message !== '') {
      pNotify(response.message, 'warning')
      return
    }
    switch (response.status) {

      case 'booking.guest':
        pNotify('Accedi e verifica la tua email per confermare', 'warning')
        break
      case 'booking.account-not-active':
        pNotify('Verifica la tua email per confermare', 'warning')
        break
      case 'booking.phone':
        pNotify('Devi inserire un numero di telefono valido', 'error')
        break
      case 'booking.create':
        pNotify('La Richiesta non è stata creata', 'warning')
        break
      case 'booking.create.exist':
        pNotify('La Richiesta già esistente per questa data, verifica nella tua sezione ordini ', 'warning')
        break
      case 'booking.denied':
        pNotify('La Richiesta non è stata rifiutata', 'warning')
        break
      case 'booking.canceled':
        pNotify('La Richiesta non è stata annullata', 'warning')
        break
      case 'booking.annulled':
        pNotify('La Richiesta non è stata annullata', 'warning')
        break
      case 'booking.completed':
        pNotify('La Richiesta non è stata completata', 'warning')
        break
      case 'booking.archived':
        pNotify('La Richiesta non è stata archiviata', 'warning')
        break
      case 'booking.accepted':
        pNotify('La Richiesta non è stata accettata', 'warning')
        break
      case 'booking.offer.fan':
        pNotify('La Proposta è riservata solo ai Fan di ' + response.data.catering.name, 'warning')
        break
      case 'booking.items.dirty':
        pNotify('La Richiesta ha dei elementi non validi', 'warning')
        break
      case 'booking.requested.catering.close':
        pNotify('Non è possibile creare la ' + 'Richiesta perchè in quel giorno a quell\'orario l\'Attività è chiusa', 'warning')
        break
      default:
        pNotify('Problema durante la Richiesta', 'error')
        break
    }

  }

  self.notifyError = function (response) {
    pNotify('C\'è stato un problema, ricarica la pagina e riprova, altrimenti scrivici ad <a href="mailto:assistenza@trovacigusto.com">assistenza@trovacigusto.com</a>', 'error')
  }

}
