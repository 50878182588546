function BookingCartModal (cartModule) {

  var self = new ModalModule()
  self.cartModule = cartModule ? cartModule : new CartModule()

  self.catering = ko.observable()
  self.user = ko.observable()
  self.mode = ko.observable('reservation')
  self.previousStep = ko.observable('')
  self.showInviteParticipants = ko.observable(false)
  self.saveBilling = ko.observable(false)
  self.showCartRegister = ko.observable(true)
  self.toggleLoginRegister = ko.observable('login')
  self.cateringProcessor = new CateringProcessorModule()

  self.datepickerOptions = ko.observable({})

  self.headerLabel('La tua Richiesta')
  self.bodyTemplate('bodyBookingCartModalTemplate')
  self.bookingCartStepTemplate = ko.observable('bookingCartServiceTemplate')
  self.headerTemplate('headerBookingRequestModalTemplate')
  self.modalSize = ko.observable('modal-lg')

  self.stepActive = 0
  self.step = ['service', 'datetime', 'address', 'food', 'resume']

  self.stepDateTimeErrorsCode = ['booking.service_date', 'booking.service_time', 'booking.too-late', 'booking.seats']

  self.stepServiceErrorsCode = ['booking.catering.inactive', 'booking.service_type', 'booking.service.unsupported']

  self.stepItemsErrorsCode = ['booking.items.empty', 'booking.delivery.priceMin', 'booking.items.dirty']

  self.stepAddressErrorsCode = ['booking.address', 'booking.delivery.distance']

  self.stepResumeErrorsCode = ['booking.too-late', 'booking.phone']

  self.dishDefaultOption = ko.pureComputed(function () {
    var menuListino = self.cartModule.cart().catering.isSupplier || self.cartModule.cart().catering.isShop ? 'Listino' : 'Menù'
    if (self.cartModule.listDishes().length <= 0)
      return 'Non ci sono voci di ' + menuListino + ' disponibili'
    return 'Seleziona dal ' + menuListino
  })

  self.serviceTypeLabel = ko.pureComputed(function () {
    if (!self.cartModule.cart() || !self.cartModule.cart().service_type) {
      return 'Prenotazione/Ordine';
    }
    switch (self.cartModule.cart().service_type()) {
      case 'table':
        return 'Prenotazione del Tavolo ' + (self.cartModule.cart().items().length > 0 ? ' + Preordine' : '');
      case 'delivery':
        return 'Acquisto con consegna a domicilio';
      case 'takeaway':
        return "Acquisto/Asporto con ritiro presso l'Attività";
      case 'preparedHome':
        return 'Chef/Catering a domicilio ' + (self.cartModule.cart().items().length > 0 ? ' + Preordine' : '');
      case 'sentHome':
        return 'Acquisto con spedizione a domicilio';
      default:
        return 'Prenotazione/Ordine';
    }
  })

  self.stepDateTimeIsComplete = ko.computed(function () {
    return self.cartModule.cart() && self.cartModule.cart().service_type() !== null && !self.stepDateTimeErrorsCode.includes(self.cartModule.bookingErrorReason())
  })

  self.stepServiceIsComplete = ko.computed(function () {
    return self.cartModule.cart() && self.cartModule.cart().service_type() !== null && !self.stepServiceErrorsCode.includes(self.cartModule.bookingErrorReason())
  })

  self.stepItemsIsComplete = ko.computed(function () {
    return self.cartModule.cart() && !self.stepItemsErrorsCode.includes(self.cartModule.bookingErrorReason())
  })

  self.stepAddressIsComplete = ko.computed(function () {
    if (!self.cartModule.cart())
      return false
    return !self.stepAddressErrorsCode.includes(self.cartModule.bookingErrorReason()) && self.cartModule.bookingAddressValid()
  })

  self.stepDateTimeErrorMessage = ko.computed(function () {
    if (!self.stepDateTimeIsComplete())
      return self.cartModule.message()
  })

  self.stepServiceErrorMessage = ko.computed(function () {
    if (!self.stepServiceIsComplete())
      return self.cartModule.message()
  })

  self.stepItemsErrorMessage = ko.computed(function () {
    if (!self.stepItemsIsComplete())
      return self.cartModule.message()
  })

  self.stepAddressErrorMessage = ko.computed(function () {
    if (!self.stepAddressIsComplete())
      return self.cartModule.message()
  })

  self.next = function () {
    var stepCurrent = self.stepActive
    if ((self.cartModule.cart().service_type() === 'sentHome' && self.stepActive === 0) || (!self.cartModule.checkServiceRequiresAddress() && self.stepActive === 1)) {
      self.stepActive = self.stepActive + 2
    } else {
      self.stepActive = self.stepActive + 1
    }
    if (self.step[self.stepActive] !== undefined) {
      self.showStep(self.step[self.stepActive])
    } else {
      self.stepActive = stepCurrent
    }
  }

  self.prev = function () {
    var stepCurrent = self.stepActive
    if ((self.cartModule.cart().service_type() === 'sentHome' && self.stepActive === 2) || (!self.cartModule.checkServiceRequiresAddress() && self.stepActive === 3)) {
      self.stepActive = self.stepActive - 2
    } else {
      self.stepActive = self.stepActive - 1
    }
    if (self.step[self.stepActive] !== undefined) {
      self.showStep(self.step[self.stepActive])
    } else {
      self.stepActive = stepCurrent
    }
  }

  self.showStep = function (step) {
    switch (step) {
      case 'service':
        self.stepService()
        break
      case 'datetime':
        self.stepDateTime()
        break
      case 'address':
        self.stepAddress()
        break
      case 'food':
        self.stepOffers()
        break
      case 'resume':
        self.stepResume()
        break
    }
  }

  self.setCatering = function (catering) {
    self.catering(self.cateringProcessor.process(catering))
  }

  self.showById = function (cartId) {
    self.cartModule.cateringId(self.catering().id)
    self.user(trovacigusto.userLogged)
    self.cartModule.loadDraftById(cartId).then(function (response) {
      if (response.success) {
        closeAllModal()
        if (!self.cartModule.isBookable())
          self.stepService()

        self.modalVisible(true)
      }
    })

  }

  self.toggleCartRegister = function () {
    self.showCartRegister(!self.showCartRegister())
  }

  self.show = function () {
    self.cartModule.cateringId(self.catering().id)
    self.user(trovacigusto.userLogged)
    self.cartModule.loadByCatering(true)
    closeAllModal()
    if (!self.cartModule.isBookable())
      self.stepService()

    if (self.cartModule.cart() && self.cartModule.cart().service_type() !== null && self.bookingCartStepTemplate() === 'bookingCartServiceTemplate')
      self.next()

    sendFacebookEvent('trackCustom', 'OpenBookingCart', { catering: self.cartModule.cateringId })
    sendFacebookEvent('track', 'InitiateCheckout', { catering: self.cartModule.cateringId })

    self.modalVisible(true)
  }

  self.enableParticipants = function () {
    self.showInviteParticipants(true)
  }

  self.stepParticipants = function () {
    if (!self.stepDateTimeIsComplete())
      return
    if (self.cartModule.cart().participants().length > 0)
      self.showInviteParticipants(true)
    self.bookingCartStepTemplate('bookingParticipantsTemplate')
    self.scrollToTop()

  }

  self.stepOffers = function () {
    self.stepActive = 3

    if (!self.catering().isOrderableOnline()) {
      self.stepResume()
      return
    }
    if (self.cartModule.cart().service_type() === 'table' && !self.catering().serviceTableBooking) {
      self.stepResume()
      return
    }
    if (self.cartModule.cart().service_type() === 'preparedHome' && !self.catering().servicePreparedBooking) {
      self.stepResume()
      return
    }
    if (!self.stepDateTimeIsComplete()) {
      self.stepDateTime()
      return
    }
    self.cartModule.update()
      .then(function (response) {
        if (response && self.stepDateTimeIsComplete()) {
          self.bookingCartStepTemplate('bookingCartItemsTemplate')
          self.cartModule.loadCateringOffers()
          //self.cartModule.loadCateringDishes()
          self.scrollToTop()
        }
      })
      .catch(function (res) {
        console.log(res)
      })

  }

  self.stepAddress = function () {
    self.stepActive = 2
    if (!self.stepDateTimeIsComplete())
      return
    self.cartModule.update()
      .then(function (response) {
        if (response && self.stepDateTimeIsComplete()) {
          self.bookingCartStepTemplate('bookingCartAddress')
          self.initMapBooking()
          self.scrollToTop()
        }
      })
      .then(function (response) {
        self.cartModule.loadLatestAddresses()
      })

      .catch(function (res) {

      })

  }


  self.stepResume = function () {
    self.cartModule.loading(true)
    location.href = '/caterings/' + self.cartModule.cateringId() + '/bookings/' + self.cartModule.cart().id() + '/checkout/'
  }

  self.stepDateTime = function () {
    self.stepActive = 1
    self.bookingCartStepTemplate('bookingCartDateTimeTemplate')
  }

  self.stepService = function () {
    self.stepActive = 0
    self.bookingCartStepTemplate('bookingCartServiceTemplate')
  }


  self.scrollToTop = function () {
    $('#modalBookingCart').animate({ scrollTop: 0 }, 'slow')
  }

  self.updateMap = ko.computed(function () {
    if (!self.cartModule.addressBookingVM() || !self.cartModule.addressBookingVM().address)
      return
    if (self.cartModule.addressBookingVM().address.latitude() || self.cartModule.addressBookingVM().address.longitude())
      self.initMapBooking()
  })

  self.initMapBooking = function () {
    const centerLatItaly = 42.403541
    const centerLngItaly = 12.8593243
    var mapBooking = document.getElementById('mapAddressBooking')
    if (mapBooking && self.cartModule.addressBookingVM()) {
      var lat = self.cartModule.addressBookingVM().address.latitude() ? self.cartModule.addressBookingVM().address.latitude() : self.catering().address.latitude
      var lon = self.cartModule.addressBookingVM().address.longitude() ? self.cartModule.addressBookingVM().address.longitude() : self.catering().address.longitude
      var myLatLng = new google.maps.LatLng(lat, lon)
      var mapOptions = {
        zoom: 15,
        center: myLatLng,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true
      }

      var map = new google.maps.Map(mapBooking, mapOptions)

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        draggable: true
      })
      google.maps.event.trigger(map, 'idle')
      google.maps.event.trigger(map, 'resize')

      marker.addListener('dragend', function (event) {
        self.cartModule.addressBookingVM().address.latitude((parseFloat(event.latLng.lat())))
        self.cartModule.addressBookingVM().address.longitude((parseFloat(event.latLng.lng())))
      })

      marker.setMap(map)

    }
  }

  self.cartLogin = function () {
    trovacigusto.modalLogin.loginRegisterModule.basicLogin()
        .then(function (response) {
          if (response.success && response.data.user) {
            trovacigusto.userLogged(response.data.user)
            self.cartModule.update()
          }
        })
  }

  self.cartRegister = function () {
    var message = ''
    trovacigusto.modalLogin.loginRegisterModule.basicRegister()
        .then(function (response) {
          if (response.success && response.data.user) {
            trovacigusto.userLogged(response.data.user)
            self.cartModule.update()
          }
          if (response.success) {
            self.onesignalGetData()
            if (self.registerType() !== 'catering' && self.registerType() !== 'supplier') {
              message = 'Ottimo! Controlla la tua casella di posta per confermare'
              trovacigusto.modalLogin.loginRegisterModule.responseMessageSuccess(message)
              pNotify(message)
            }
          }
        })
  }

  self.addFavoriteCatering = function () {
    if (!trovacigusto.userLogged()) {
      trovacigusto.modalLogin.headerLabel('Già Utente?<br>Accedi per diventare Fan')
      trovacigusto.modalLogin.show()
      return
    }

    trovacigusto.flagVM.store('catering', self.catering().id).then(function (response) {
      if (response.success) {
        pNotify('Ora sei Fan di ' + response.data.name)
        self.catering().isFavorite(true)
        self.cartModule.update()
        return
      }
      if (response.status === 'flag.exist') {
        self.cartModule.update()
      } else
        pNotify('C\'è stato un problema!', 'error')
    })
  }

  self.preorderInfo = function () {
    /*Bugsnag.notify(new Error('call preorderinfo'), null, function (err, event) {
      if (err) {
        console.log('Failed to send report because of:\n' + err.stack)
      } else {
        console.log('Successfully sent report "' + event.errors[0].errorMessage + '"')
      }
    })*/
    Swal.fire({
      title: 'Pre-ordine',
      html: 'Pre-ordinando, puoi risparmiare tempo successivamente e puoi utilizzare i tuoi FanCredits per ridurre il totale da pagare',
      icon: 'info',
      customClass: {
        confirmButton: 'btn btn-info mg-r-xs',
      },
    })
  }


  return self
}

$('#modalBookingCart').on('shown.bs.modal', function () {
  $(document).off('focusin.modal')
})
