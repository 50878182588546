function BookingModel (item) {
  var self = this

  self.id = ko.observable(item ? item.id : null)
  self.user_id = ko.observable(item ? item.user_id : null)
  self.catering_id = ko.observable(item ? item.catering_id : null)
  self.ticket_id = ko.observable(item ? item.ticket_id : null)
  self.booking_code = ko.observable(item ? item.booking_code : null)
  self.session_id = ko.observable(item ? item.session_id : null)
  self.state = ko.observable(item ? item.state : null)
  self.mode = ko.observable(item ? item.mode : null)
  self.phone = ko.observable(item ? item.phone : null)
  self.seats = ko.observable(item ? item.seats : null)
  self.declared_seats = ko.observable(item ? item.declared_seats : null)
  self.details = ko.observable(item ? item.details : null)
  self.dirty = ko.observable(item ? item.dirty : null)
  self.user_archived = ko.observable(item ? item.user_archived : null)
  self.catering_archived = ko.observable(item ? item.catering_archived : null)
  self.error_data = ko.observable(item ? item.error_data : null)
  self.error_code = ko.observable(item ? item.error_code : null)
  self.service_type = ko.observable(item ? item.service_type : null)
  self.service_date = ko.observable(item ? item.service_date : null)
  self.service_time = ko.observable(item ? item.service_time : null)
  self.service_price = ko.observable(item ? item.service_price : null)
  self.total = ko.observable(item ? item.total : null)
  self.price = ko.observable(item ? item.price : null)
  self.subtotal_discountable = ko.observable(item ? item.subtotal_discountable : null)
  self.subtotal = ko.observable(item ? item.subtotal : null)
  self.is_total_discount = ko.observable(item ? item.is_total_discount : null)
  self.discountPromotion = ko.observable(item ? item.discountPromotion : null)
  self.discountCoupon = ko.observable(item ? item.discountCoupon : null)
  self.discount = ko.observable(item ? item.discount : null)
  self.discount_type = ko.observable(item ? item.discount_type : null)
  self.extra_discount_price = ko.observable(item ? item.extra_discount_price : null)
  self.extra_discount_reason = ko.observable(item ? item.extra_discount_reason : null)
  self.currency = ko.observable(item ? item.currency : null)
  self.note = ko.observable(item ? item.note : null)
  self.catering_note = ko.observable(item ? item.catering_note : null)
  self.require_billing = ko.observable(item ? item.require_billing : false)
  self.confirm_mode = ko.observable(item ? item.confirm_mode : null)
  self.coupon_id = ko.observable(item ? item.coupon_id : null)
  self.donation_amount = ko.observable(item ? item.donation_amount : 0)

  self.dates = item && item.dates ? item.dates : null
  self.catering = item && item.catering ? item.catering : null
  self.user = item && item.user ? item.user : null
  self.service_datetime = ko.observable(item ? item.service_datetime : null)
  self.response_avg = ko.observable(item ? item.response_avg : 60)

  //self = ko.mapping.fromJS(item);
  self.items = ko.observableArray([])
  self.participants = ko.observableArray([])
  self.address = ko.observable(item && item.address ? item.address : null)
  self.status = ko.observable(item && item.status ? item.status : null)
  self.coupon = ko.observable(item && item.coupon ? item.coupon : null)
  self.donation = ko.observable(item && item.donation ? item.donation : null)
  self.transaction = ko.observable(item && item.transaction ? item.transaction : null)
  self.proposal = ko.observable(item && item.proposal ? ko.mapping.fromJS(item.proposal) : null)
  self.billing = new BookingBillingModel(item.billing)

  if (item.items && item.items.length > 0) {
    item.items.forEach(function (item, index) {
      self.items.push(new BookingItemModel(item))
    })
  }

  if (item.participants && item.participants.length > 0) {
    item.participants.forEach(function (item, index) {
      self.participants.push(item)
    })
  }

  if (self.transaction())
    self.transaction().state = ko.observable(self.transaction().state)

  return self

}
